<template>
  <div class="Login">
    <div class="background-colour-section">
      <div class="login-div text-center">
        <img
          src="https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo.png"
          width="127"
          srcset="
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo-p-500.png   500w,
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo-p-800.png   800w,
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo-p-1080.png 1080w,
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo.png        1365w
          "
          sizes="126.9937515258789px"
          alt=""
          class="static-page-image-icon mx-auto mt-32 mb-6"
        />
        <template v-if="!forgotPassword">
          <h2 class="heading text-4xl">Welcome to Savvy</h2>
          <div class="text-block text-xl">
            Login with your Google Account below to get started
          </div>
          <Button
            id="SavvyNavigate-create-new-google"
            href="#"
            class="new-savvy maxsize-30"
            @click.native="login('google', $event)"
          >
            <!-- class="button-2 link-hover-raise" -->
            Sign in with Google
          </Button>

          <Button
            id="SavvyNavigate-loginManually"
            href="#"
            class="new-savvy secondary maxsize-30 mb-6"
            @click.native="toggleManualLogin"
          >
            Login with Username / Password
          </Button>
          <!-- class="button-2 secondary-button link-hover-raise" -->
          <!-- <Button
          id="SavvyNavigate-login-google"
          href="#"
          class="new-savvy secondary maxsize-30"
          @click.native="login('google', $event)"
        >
          or login to an existing Savvy account
        </Button> -->
          <div class="manual-login" v-if="manualLogin">
            <!-- <div>Login Manually</div> -->

            <div>
              <input
                class="m-1 p-3 border rounded"
                type="text"
                placeholder="Email"
                v-model="email"
              />
              <input
                class="m-1 p-3 border rounded"
                type="password"
                placeholder="Password"
                v-model="password"
              />
            </div>
            <template v-if="loading">
              <Spinner />
            </template>
            <template v-else>
              <Button
                class="selector new-savvy"
                style="margin-top: 20px;"
                text="Sign In with password"
                @click.native="loginPassword"
              />
              <Button
                class="signup new-savvy secondary"
                text="Create Account"
                @click.native="createWithPassword"
              />
              <div class="cursor-pointer" @click="enableForgotPassword">I forgot my password</div>
            </template>
          </div>
        </template>
        <div v-if="forgotPassword" class="forgot-password">
          <div v-if="resetEmailSentAddress">
            We sent your recovery email to {{ resetEmailSentAddress }}
          </div>
          <template v-else>
            <template v-if="oobCode">
              <label for="pw">Enter a new password:</label>
              <input
                class="m-1 p-3 border rounded"
                type="password"
                name="pw"
                placeholder="Password"
                v-model="password"
              />
              <label name="verify-pw">Verify your password:</label>
              <input
                class="m-1 p-3 border rounded"
                type="password"
                name="verify-pw"
                placeholder="Password"
                v-model="passwordVerify"
              />
              <div v-if="password && passwordVerify && password !== passwordVerify">
                Passwords do not match
              </div>
              <Button
                class="signup new-savvy secondary"
                text="Set Password"
                @click.native="setNewPassword"
              />
            </template>
            <template v-else>
              <div>Enter your email and we'll send you a recovery email</div>
              <input
                class="m-1 p-3 border rounded"
                type="text"
                placeholder="Email"
                v-model="email"
              />
              <Button
                class="signup new-savvy secondary"
                text="Send Recovery Email"
                @click.native="sendRecoveryEmail"
              />
              <div class="cursor-pointer" @click="forgotPassword = false">I changed my mind</div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/auth'
import { loginViaService } from '@/boot-up/authentication'
import getUrlDict from '@/helpers/getUrlDict'

export default {
  components: {},
  props: {
    auto: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      // userEmail: '',
      // userPhoneNumber: '', // '+1 650-555-2218',
      email: '',
      password: '',
      useManual: false,
      forgotPassword: false,
      oobCode: null,
    }
  },
  computed: {
    manualLogin() {
      return this.query.loginauth === 'reflect' || this.useManual
    },
    query() {
      return this.$route.query || {}
    },
    user() {
      return this.$store.getters.user
    },
    emailSent() {
      return this.$store.state.emailLoginSent
    },
    collectingTextCode() {
      return this.$store.state.collectingTextCode
    },
  },
  // created() {
  //   if (this.auto) {
  //     if (this.user === null) this.login('google')
  //     else {
  //       this.$router.push('/login')
  //     }
  //   }
  // },
  mounted() {
    this.getUrlCode()
  },
  methods: {
    async getUrlCode() {
      /* https://app.trysavvy.com/login */
      const dict = getUrlDict()
      if (dict.mode === 'resetPassword') {
        try {
          const email = await Firebase.auth().verifyPasswordResetCode(dict.oobCode)
          this.email = email
          this.oobCode = dict.oobCode
          this.forgotPassword = true
          this.resetEmailSentAddress = false
        } catch (error) {
          console.error(error)
          this.$message.error(`Error Recovering password: ${error.message}`)
        }
      }
    },
    createWithPassword() {
      this.$emit('loading', true)
      Firebase.auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .catch(error => {
          this.$emit('loading', false)
          console.error('Error Signing Up with Email/Password:', error)
          this.$message.error(`Error Signing Up: ${error.message}`)
        })
    },
    loginPassword() {
      this.$emit('loading', true)
      Firebase.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch(error => {
          this.$emit('loading', false)
          console.error('Error Signing In with Email/Password:', error)
          this.$message.error(`Error Signing In: ${error.message}`)
        })
    },
    toggleManualLogin(evt) {
      if (evt) evt.preventDefault()
      this.useManual = true
    },
    login(provider, evt) {
      if (evt) {
        evt.preventDefault()
      }
      this.$emit('loading', true)
      // if (provider !== 'phone') {
      //   this.$store.commit('setUser', undefined)
      // }

      // const email = this.userEmail
      // const phoneNumber = this.userPhoneNumber
      return (
        loginViaService(provider)
          // .then(() => {
          //   if (!this.$store.getters.user) {
          //     this.$store.commit('setUser', null)
          //   }
          //   if (provider === 'email') {
          //     this.$store.commit('setEmailLoginSent', true)
          //   } else if (provider === 'phone') {
          //     this.$store.commit('setCollectingTextCode', true)
          //   }
          // })
          .catch(error => {
            console.error('error', error)
            this.$message.error(error.message)
            this.$emit('loading', false)
            // this.$store.commit('setUser', null)
            // this.$router.push('/login')
          })
      )
    },
    enableForgotPassword() {
      this.forgotPassword = true
    },
    async sendRecoveryEmail() {
      if (!this.email) return
      this.resetEmailSentAddress = this.email
      await Firebase.auth().sendPasswordResetEmail(this.email)
      this.email = ''
    },
    async setNewPassword() {
      try {
        await Firebase.auth().confirmPasswordReset(this.oobCode, this.password)
        this.forgotPassword = false
        this.oobCode = null
        await this.loginPassword()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/components/utilities/styles/Input.scss';

.Login.main-view {
  // text-align: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  color: #2d3958;
  font-size: 15px;
  line-height: 1;
  padding: 0;

  .background-colour-section {
    min-height: 100vh;
    padding-top: 100px;
    // background-color: #ebfbff;
    // background-image: url(https://uploads-ssl.webflow.com/5ecc71a…/5ecc71a…_Wave%20Blue.svg);
    background-position: 0px 0px;
    background-size: cover;
  }

  .login-div {
    max-width: 85%;
    min-height: 400px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
  }
  .static-page-image-icon {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }
  h2 {
    margin-bottom: 24px;
    line-height: 1.15;
    font-weight: 500;
    letter-spacing: -0.03em;
  }
  .heading {
    margin-top: 25px;
    font-size: 52px;
    text-align: center;
  }

  .text-block {
    font-size: 18px;
    text-align: center;
  }

  .manual-login {
    margin-top: 25px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
</style>
